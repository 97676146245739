@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts";

@layer utilities {
	.scroll-snap-none {
		scroll-snap-type: none;
	}
	.scroll-snap-x {
		scroll-snap-type: x mandatory;
	}
	.scroll-snap-y {
		scroll-snap-type: y mandatory;
	}
}

* {
	font-size: 16px;
	scroll-behavior: smooth;
}

select {
    padding: 10px;
    border: 1px solid black;
    border-radius: 4px;
}

/* .slide-box {
	@apply lg:h-85vh lg:h-85vh xl:h-90vh;
} */

@media screen and (orientation: landscape) and (max-width: 1024px) {
	.slide-box,
	.slide-content {
		height: auto !important;
		max-height: auto !important;
	}

	.slide-content {
		@apply my-8;
	}
}

h2 {
	@apply xxsm:text-lg xxsm:mb-2 xxsm:leading-none xsm:text-lg xsm:mb-2 xsm:leading-none sm:text-base sm:my-2 sm:leading-none md:text-xl lg:text-2xl xl:text-3xl hd:text-5xl text-primary-default;
	font-family: "Montserrat", sans-serif;
	font-weight: 900;
	text-transform: capitalize;
}

p {
	@apply xxsm:text-xs xsm:text-xs sm:text-base lg:text-base leading-none mt-4;
	font-family: "Montserrat", sans-serif;
	font-weight: 300;
}

/* .video-responsive {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
}

.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
} */

.video-responsive {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
}

.video-responsive iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}
